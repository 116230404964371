<template>
  <div class="popup_wrap" style="width:800px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL010P090.001') }}</h1>
      <div class="content_box mt10">
        <div class="cp-pre-container mb10">
          <pre class="txt_desc cp-pre">{{ $t('cp.CTRL010P090.004') }}</pre>
        </div>
        <table class="tbl_row cp-table">
          <colgroup>
            <col width="100px">
            <col>
            <col width="100px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('cp.CTRL010P090.002') }}</th>
              <td>
                <select v-model="sizeCd" @change="[setGridFilter(), setSelectTagFilter()]">
                  <option value="" selected>{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in sizeCdList" :key="idx" :value="item">{{ item }}`</option>
                </select>
              </td>
              <th>{{ $t('cp.CTRL010P090.003') }}</th>
              <td>
                <select v-model="typeCd" @change="[setGridFilter()]">
                  <option value="" selected>{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in typeCdList" :key="idx" :value="item">{{ item }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt10"></div>
        <div id="realgrid" style="width: 100%; height: 500px" />
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="select">{{ $t('cp.COMMON.002') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'orgCd', dataType: 'text' },
  { fieldName: 'sizeCd', dataType: 'text' },
  { fieldName: 'typeCd', dataType: 'text' }
]
const columns = [
  { name: 'orgCd', fieldName: 'orgCd', visible: false },
  // eslint-disable-next-line
  { name: 'sizeCd', fieldName: 'sizeCd', header: { text: app.$t('cp.CTRL010P090.002') }, editable: false, width: 338, styleName: 'text_left', renderer: { type: 'html', template: '${value}`' } },
  { name: 'typeCd', fieldName: 'typeCd', header: { text: app.$t('cp.CTRL010P090.003') }, editable: false, width: 358, styleName: 'text_left' }
]

export default {
  name: 'CntrSzTypListPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          position: '',
          cntrSzTyp: []
        }
      }
    }
  },
  data () {
    return {
      containerData: [],
      sizeCdList: [],
      typeCdList: [],
      sizeCd: '',
      typeCd: '',
      chkHistory: []
    }
  },
  mounted () {
    const $vm = this

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')

    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)

    gridView.header.heights = [30]
    gridView.footers.visible = false
    gridView.setDisplayOptions({
      rowHeight: 34,
      fitStyle: 'even',
      selectionStyle: 'rows',
      columnMovable: false
    })
    gridView.setStateBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.setCheckBar({
      visible: true
    })
    gridView.setSortingOptions({
      enabled: false
    })
    gridView.setFilteringOptions({
      enabled: false,
      commitBeforeFiltering: true,
      handleVisibility: 'none'
    })

    gridView.onItemAllChecked = (_grid, checked) => {
      const lastCntrSzTyp = gridView.getJsonRows().map(row => row.orgCd).at(-1)
      if (checked) {
        $vm.chkHistory.push(lastCntrSzTyp.replace('-', ' '))
      } else {
        $vm.chkHistory = []
      }
    }

    gridView.onItemChecked = (_grid, itemIndex, checked) => {
      const lastCntrSzTyp = gridView.getValue(itemIndex, 'orgCd')
      if (checked) {
        $vm.chkHistory.push(lastCntrSzTyp.replace('-', ' '))
      } else if ($vm.chkHistory.at(-1) === lastCntrSzTyp) {
        $vm.chkHistory.pop()
      }
    }

    $vm.getContainerData()
    gridView.onDataLoadComplated = () => {
      gridView.setCheckBar({ syncHeadCheck: true })
      $vm.updateCheckState()
    }
  },
  methods: {
    getContainerData () {
      cpCommonInfo.cntrSzTypList().then(response => {
        const containerData = response.data.map(row => row.cntrSzTyp).map(orgCd => ({
          orgCd: orgCd,
          sizeCd: orgCd.split('-')[0],
          typeCd: orgCd.split('-')[1]
        }))
        this.sizeCdList = [...new Set(containerData.map(row => row.sizeCd))]
        this.typeCdList = [...new Set(containerData.map(row => row.typeCd))]
        this.containerData = containerData

        provider.setRows(containerData)
      }).catch(err => {
        console.error(err)
      })
    },
    updateCheckState () {
      const cntrSzTyp = this.parentInfo.cntrSzTyp
      if (Array.isArray(cntrSzTyp) && cntrSzTyp.length !== 0) {
        const chkedRowIdxList = gridView.getJsonRows().map((row, idx) => cntrSzTyp.includes(row.orgCd) ? idx : -1).filter(idx => idx !== -1)
        gridView.checkItems(chkedRowIdxList)
      }
    },
    setSelectTagFilter () {
      if (this.sizeCd !== '') {
        this.typeCdList = [...new Set(this.containerData.filter(row => row.sizeCd === this.sizeCd).map(row => row.typeCd))]

        if (this.typeCd !== '') {
          const testOrgCd = `${this.sizeCd}-${this.typeCd}`

          if (!this.containerData.map(row => row.orgCd).includes(testOrgCd)) {
            this.typeCd = ''
            this.setGridFilter()
          }
        }
      } else {
        this.typeCdList = [...new Set(this.containerData.map(row => row.typeCd))]
      }
    },
    setGridFilter () {
      gridView.clearColumnFilters('sizeCd')
      gridView.clearColumnFilters('typeCd')

      if (this.sizeCd !== '') {
        const columnName = 'sizeCd'
        const criteria = `(value = "${this.sizeCd}")`

        gridView.setColumnFilters(columnName, [{
          name: columnName,
          criteria: criteria,
          text: columnName,
          active: true
        }])
      }

      if (this.typeCd !== '') {
        const columnName = 'typeCd'
        const criteria = `(value = "${this.typeCd}")`

        gridView.setColumnFilters(columnName, [{
          name: columnName,
          criteria: criteria,
          text: columnName,
          active: true
        }])
      }
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    layerClose () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    select () {
      gridView.clearColumnFilters('sizeCd')
      gridView.clearColumnFilters('typeCd')

      const chkedRows = gridView.getCheckedRows().map(idx => gridView.getJsonRows()[idx])
      const cntrSzTyp = chkedRows.map(row => `${row.sizeCd}-${row.typeCd}`)
      const cntrSzTypLength = cntrSzTyp.length
      const lastCntrSzTyp = !['', undefined].includes(this.chkHistory.at(-1)) ? this.chkHistory.at(-1) : cntrSzTyp.at(-1)

      this.$emit('close', { cntrSzTyp: cntrSzTyp, cntrSzTypLength: cntrSzTypLength, lastCntrSzTyp: lastCntrSzTyp })
      this.layerClose()
    }
  }
}
</script>
